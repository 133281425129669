.footerContainer{
    margin:auto;
    margin-top: 30px;
    padding-top: 1px;
    display: flex;
    padding-bottom: 30px;
    border-bottom: #66666659 solid 0.3px;
    /* border-top: #66666659 solid 0.3px; */
    max-width: 1280px;
    bottom: 0px!important;
}

.copyrightFooter{
    text-align: center;
    font-size: 14px!important;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footerLeft{
    width: 50%;
}

.footerMenu1{
    display: grid;
    width: 25%;
}

/* .footerMenu2{
    display: grid;
    width: 25%;
} */

.bothFootermenuLinks{
    display: contents;
}

.navButton{
    text-decoration: none;
    color: #2D3648;
    border: none;
    margin-bottom:10px;
}

/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footerContainer{
        max-width: 1080px;
    }
}
/* 1200px end*/


/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .footerContainer{
        border-top:none;
        margin-top: 0px;
        width: 90%;
    }
}
/* 601-768px end*/

@media only screen and (max-width: 600px) {
    .footerContainer{
        padding-top: 60px;
        padding-left: 20px;
    }

    .bothFootermenuLinks{
        display: block;
    }
    .footerMenu1{
        display: grid;
        width: 100%;
    }
/*     
    .footerMenu2{
        display: grid;
        width: 100%;
    } */
}
